import React, { LegacyRef, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'
import ab_img from 'assets/Images/about-img.svg'
import Apehattan_video from 'assets/Images/Apehattan.mp4'

export const About = ({
  slide1Ref,
  slide2Ref,
}: {
  slide1Ref: LegacyRef<HTMLDivElement> | undefined
  slide2Ref: LegacyRef<HTMLDivElement> | undefined
}) => {
  const aboutRef: any = useRef(null)

  const showNext = () => {
    const includedSection = document.querySelector('#included')
    if (includedSection) includedSection.scrollIntoView()
  }

  const showHeader = () => {
    window.location.hash = ''
    window.scrollTo(0, 0)
  }

  return (
    <section className='about' id='about' ref={aboutRef}>
      <div className='container'>
        <span className='next mb-6' onClick={showHeader}>
          <Icon icon='akar-icons:chevron-up' />
        </span>
        <div className='row'>
          <div className='col-lg-6 col-12 slides slide_in left' ref={slide1Ref}>
            <h2>Welcome To Apehattan</h2>
            <div className='d-flex gap-4 my-5'>
              <Link
                className='btn btn-sm btn-outline'
                to='apehattan-litepaper.pdf'
                target='_blank'
              >
                Litepaper
              </Link>
              <button className='btn btn-sm btn-outline'>Read FAQs</button>
            </div>
            <p>
              Apehattan is an AI-powered, blockchain-driven open-world metaverse platform that revolutionizes digital creation by merging advanced AI with decentralized technology.
              <br />
              <br />
              Designed for accessibility, scalability, and real-world utility, Apehattan empowers users to build, own, and monetize immersive experiences with AAA-quality gameplay.
              <br />
              <br />
              <p>
                By leveraging blockchain, it ensures true digital ownership and a thriving in-game economy, while its AI-driven tools enable developers, creators, and everyday users to design high-fidelity metaverse experiences without limitations.
              </p>
            </p>
          </div>
          <div
            className='col-lg-6 col-12 slides slide_in right'
            ref={slide2Ref}
          >
            <video
              width='100%'
              height='auto'
              poster={ab_img}
              autoPlay={true}
              muted={true}
              controls
              className='click'
              playsInline
            >
              <source src={Apehattan_video} type='video/mp4' />
              Your browser does not support HTML5 video.
            </video>
          </div>
        </div>
        <div className='text-center my-5'>
          <button className='btn btn-lg btn-launch btn-green'>
            Coming Soon
          </button>
        </div>
        <span className='next' onClick={showNext}>
          <Icon icon='akar-icons:chevron-down' />
        </span>
      </div>
    </section>
  )
}
